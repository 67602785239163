/*
constants to be imported and used throughout app

*/
import { DeploySwitchConfig } from "../config/keys";



// export const DB_ROOT = 'story_app_data';
export const APP_VERSION = '0.0.117' + DeploySwitchConfig.dev_string;
// export const DB_ROOT = 'test6';
// export const DB_ROOT = 'story_app_root_3';
export const DB_ROOT = 'storycraft_root_1';
export const STORE_ROOT = DB_ROOT;

// this will controls if redux will combine database data with data from initial files
export const USE_INIT_DATA = true;

export const DEV_MODE = DeploySwitchConfig.dev_mode;
// export const DEV_MODE = false;
export const DB_USER_DATA = DB_ROOT+'/user_data';
export const DB_PROJECT_DATA = DB_ROOT+'/project_data';
export const DB_BETA_APPROVED = DB_ROOT+'/beta/approved';


export const VP_XFORM_POS_TYPES = ['card', 'region', 'location'];
export const VP_XFORM_SCALE_TYPES = ['card_scale', 'region_scale'];
export const DELETABLE_TYPES = ['character', 'prop', 'location', 'event', 'card', 'region',];

export const ITEM_TYPES_TO_LOAD_FROM_DB = [
    'character', 'prop', 'location', 'event', 
    'card', 'region', 'flow_viewport', 'timeline',
    'map_viewport', 'document', 'thread', 'attribute',
    'group'
];

export const USE_QUILL_EDITOR = true;

// I'm undecided as to whether users shoudl be allowed to write to db for example project
// it might depend on if i creata a common one for all users or make a new copy per user
// leaning to latter which means i should leave this as  true
export const EXAMPLE_PROJ_WRITE_TO_DB = true;
export const DISABLE_REDUX_DEVTOOLS_ON_RELEASE = true;

export const DEBUG_SHOW_ARRAY = [1];

var megabyte = 1048576;
export const USER_STORAGE_BYTE_LIMIT = 10*megabyte;
export const UPLOAD_FILE_BYTE_LIMIT = 2*megabyte; 