// export const FirebaseConfig = {
//     apiKey: "AIzaSyBiIK8l9LPAmRby3Sd2cczp50XAsz9Ih00",
//     authDomain: "avi-firebase-project.firebaseapp.com",
//     databaseURL: "https://avi-firebase-project.firebaseio.com",
//     projectId: "avi-firebase-project",
//     storageBucket: "avi-firebase-project.appspot.com",
//     messagingSenderId: "650930383127",
//     appId: "1:650930383127:web:79872bc239c1b262436f52"
//   };

// above is my old project, below is my new one
  export const FirebaseConfig = {
    apiKey: "AIzaSyAmIDLw_QYCqh9VFhugkdW8knw26M2YH9U",
    authDomain: "story-craft-181e2.firebaseapp.com",
    databaseURL: "https://story-craft-181e2-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "story-craft-181e2",
    storageBucket: "story-craft-181e2.appspot.com",
    messagingSenderId: "401594344822",
    appId: "1:401594344822:web:d4ff956251658b92b70657",
    measurementId: "G-F6B3NY0YFQ"
  };
  

  export const DeploySwitchConfig = {
    dev_mode: false,
    dev_string: '',
    reCaptchaSiteKey:'6LfrM6ApAAAAAECzoitI2F8_L04KPWQzcld3ZJf5'
  };