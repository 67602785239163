
import React, { Component } from 'react';
import './Message.css';
import { connect} from 'react-redux';
import Button from '../ui_elements/inputs/Button';


class Message extends Component {

    constructor(props) {
        super(props);
        this.handleClickOK = this.handleClickOK.bind(this);

    }

    handleClickOK(event) {
        this.props.close_modal()
    }
    newlineText(text) {
        return text.split('\n').map(str => <p>{str}</p>);
    }
    render() {
        let modal_input_data = {}
        if (this.props.hasOwnProperty('modal_input_data')){
            modal_input_data = this.props.modal_input_data;
        }
        let message_text = '';
        if (modal_input_data.hasOwnProperty('message')){
            message_text = modal_input_data['message'];
        }
        let message_style = {};
        if (modal_input_data.hasOwnProperty('style')){
            message_style = modal_input_data['style'];
        }
        let message_class = '';
        if (modal_input_data.hasOwnProperty('className')){
            message_class = modal_input_data['className'];
        }
        let okButtonLabel = 'OK'
        if (modal_input_data.hasOwnProperty('okButtonLabel')){
            okButtonLabel = modal_input_data['okButtonLabel']
        }

        return (

            <div className = 'CreateNewCharacter'>
                <div className={message_class + ' modalMessage'} style={message_style}>
                    {this.newlineText(message_text)}
                </div>
                <Button 
                    onClickFunction={this.handleClickOK} 
                    label={okButtonLabel}
                    style={Object.assign({}, {position:'absolute', height:"45px", width:"140px", right:"10px",bottom:"4px"})}
                />
            </div>
        )
    };
};

const mapStateToProps = (state, props) => {
    return {
        // openModalWindow:state.ui_data.openModalWindow['create_'+props.item_type],
        // is_uploading:state.ui_data.layout.is_uploading,
        // project_data:state.project_data,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        close_modal: () => dispatch({type:'CLOSE_MODAL_WINDOW', modal_name:'message'}),
   
    }
};

export default connect(mapStateToProps, mapDispatchToProps )(Message);
// export default CreateNewCharacter;
