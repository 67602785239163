
const reducer_mouse = (state, newState, action) => {
    // let newState = {...state};

      if(action.type === 'ON_MOUSE_DOWN'){
        // console.log('RD ON_MOUSE_DOWN', action, )

        //TODO should aim to retire dragging to be replaced by dragging_b
        
        let mousePos = action.mousePos;
        let item_id  = action.item_id;
        let item_type  = action.item_type;
        let item  = action.item || null;

        newState.ui_data.control = {...state.ui_data.control}
        newState.ui_data.layout = {...state.ui_data.layout}
        newState.ui_data = {...state.ui_data}

        newState.ui_data.control.mouse_down_pos = mousePos
        newState.ui_data.control.mouse_current_pos = mousePos
        newState.ui_data.control.dragging = [item_type, item_id, item];
        newState.ui_data.control.mouse_drag_offset = [0,0];

        if (item_type == 'handle'){
            if (item_id == 'attribute_panel'){
                let cur_width = state.ui_data.layout.attribute_panel_width;
                newState.ui_data.layout.attribute_panel_width_mouse_down = cur_width;
            }
        }

    }

    if(action.type === 'ON_MOUSE_OVER'){
        // //AAA console.log('RD ON_MOUSE_OVER', action, )

        newState.ui_data.control = {...state.ui_data.control}
        newState.ui_data = {...state.ui_data}
        newState.ui_data.control.hovering = [action.item_type, action.item_id]
    }


    if(action.type === 'ON_MOUSE_UP'){
        // //AAA console.log('IN MOUSE UP')
        //AAA console.log('RD ON_MOUSE_UP', action, )

        newState.ui_data.control = {...state.ui_data.control}
        newState.ui_data = {...state.ui_data}
        
        newState.ui_data.control.dragging = [null, null, null];
        newState.ui_data.control.mouse_down_pos = [null, null];
        // newState.ui_data.control.mouse_drag_offset = [0, 0];
        newState.ui_data.control.begin_drag = false;
    }

    if(action.type === 'ON_MOUSE_DRAG'){
        // //AAA console.log('IN MOUSE UP')
        // //AAA console.log('RD ON_MOUSE_DRAG', action, )

        newState.ui_data.control = {...state.ui_data.control}
        newState.ui_data = {...state.ui_data}

        newState.ui_data.control.mouse_current_pos = action.mouse_current_pos;
        newState.ui_data.control.mouse_drag_offset = action.mouse_drag_offset;
        newState.ui_data.control.begin_drag = action.begin_drag;

    }



    

    return newState;

};
export default reducer_mouse;