import { Route, BrowserRouter as Router, Switch, Link, } from 'react-router-dom';
import './Home.css';
import React from 'react';
import { useSelector, useDispatch } from "react-redux";

import { useAuth } from '../contexts/AuthContext';
import * as Constants from '../constants/constants';

import { database } from "../config/firebase";

// import firebase from "firebase";
import bg_image from '../images/landing_page_bg_01.jpg';
import flow_view_image from '../images/flow_view.jpg';
import char_view_image from '../images/character_view.jpg';
import map_view_image from '../images/map_view.jpg';
import timeline_map_view_image from '../images/timeline_view.jpg';

import * as actionCreator_auth from '../store/actions/actions_auth';
import * as actionCreator_ui from '../store/actions/actions_ui';
import * as actionCreator_users from '../store/actions/actions_users';
import * as actionCreator_database from '../store/actions/actions_database';
import UserItem from '../components/ui_elements/objects/user_item/UserItem';
import FoldoutPanelGenerator from '../components/ui_elements/objects/foldout_panel/FoldoutPanelGenerator';
import ModalWindowGenerator from '../components/modal_panels/ModalWindowGenerator';




import '../components/main_panels/BannerPanel.css';

// import SignUpWindow from '../components/modal_panels/SignUpWindow';
// import LoginPage from './LoginPage';

let blurb_string = "\n\nDiscover a new way to interact with your stories.\n\n\n\n\n\n\n\n"

let blurb_string_note = "* StoryCraft is in development and may contain bugs and incomplete features"

let blurb_string_landing = "\n\nThis landing page is a placeholder.\n\n\
                    The design and copy is not necessarily representative of where it will end up."


let blurb_string_b = "More than just words on a page - Characters, places, props and events, all come together to create a living breathing world.\n\n\
StoryCraft lets you interact with those elements in a way not previously possible."

let blurb_string_c = "Create backstory and personality traits with attributes you can track and change over time.\n\nLink items to define, and easily find related ojects."

let blurb_string_d = "Chart out your world with maps you can interact with.\nSee where your characters or props are at any given time, and watch them move from place to place as your story progresses"


let blurb_string_e = "Get your thoughts down quickly with StoryCraft's non linear tools.\n\n\
Branch plotlines, and visually layout your story, any way that makes sense to you.\n\n \
Work big to small, nesting layers of detail as deep as you need to go.\n\n\
Whether you're writing the next Lord of the Rings, or just experimenting with some quick ideas, StoryCraft will scale with you."

let beta_message = "Welcome to Storycraft.\n\nTo apply for Beta access, please send a request email to storycraft.app@gmail.com\n\
 \nMake sure to use the same email address you signed up with. Please include in the message where you heard about the app\
 as well as your profession/area of interest."

export default function Home() {
    // const history = useHistory();
    const dispatch = useDispatch()

    // let currentUser = firebase.auth().currentUser;
    const { currentUser} = useAuth()

    // todo this might be a dumb way of doing this. (storing beta approved in redux. Falling back to what I know -ish)
    let is_beta_approved = useSelector( state => state.context_data.beta_approved)
    let foldout_data = useSelector( state => state.ui_data.foldout_panel)


    function handleLogout(e) {
        dispatch(actionCreator_auth.sign_out_user())
    }

    function handleUserClick(e) {
        console.log('UserItem handleUserClick')
        dispatch(actionCreator_ui.open_foldout_panel('user_settings', 'UserSettingsIcon'))
    }


    function foldoutPanelBuild() {

        if (foldout_data){
        //   let panel_name = this.props.ui_data.foldout_panel['panel_name']
        //   let element_id = this.props.ui_data.foldout_panel['element_id']
        //   let extra_data = this.props.ui_data.foldout_panel['extra_data']
          let panel_name = foldout_data['panel_name']
          let element_id = foldout_data['element_id']
          let extra_data = foldout_data['extra_data']
          // return <FoldoutPanel panel_name={panel_name} element_id={element_id} >
          //   <p>this is a test</p>
          // </FoldoutPanel>
          return <FoldoutPanelGenerator panel_name={panel_name} element_id={element_id} extra_data={extra_data} />
        }
    }


    let email = '';
    let user_is_varified = false;
    if (currentUser){
        email = currentUser.email;
        // history.push("/app")
        
        // dispatch(actionCreator_users.set_current_user(currentUser.uid))

        let db_path = Constants.DB_USER_DATA
        dispatch(actionCreator_database.load_user_data_from_db(currentUser.uid, db_path))

        user_is_varified = currentUser.emailVerified;
        // console.log('currentUser', currentUser)
        // console.log('user_is_varified', user_is_varified)

    }
    else{
        console.log('no currentUser. pushing history now')
        // history.push("/")
    }

    // let beta_message = 'querying beta list...'
    // if (is_beta_approved === false){
    //     beta_message = 'NO SOUP FOR YOU'
    // }
    // if (is_beta_approved === true){
    //     beta_message = 'Congratulations, you\'re in'
    // }

    let link_buttons = []

    // top:4px;
    // height: 32px;
    // width: 32px;

    let beta_message_element = null
    
    if (currentUser){

        let go_to_proj_button = <div key='qq' className='signupButton HomePageGoToProjects'> loading... </div>
        if (is_beta_approved === true && user_is_varified === true){
            go_to_proj_button = <Link key='ww' className='signupButton signupButton_hover HomePageGoToProjects'to='/app'>Go to projects</Link>
        }
        else if (is_beta_approved === false){
            go_to_proj_button = <div key='ee' style={{right:'2px', width:'204px'}} className='signupButton HomePageGoToProjects'> Need beta permission </div>

            beta_message_element = <div  className='beta_message'>{beta_message}</div>
        }
        if (user_is_varified === false){
            go_to_proj_button = <div key='rr' style={{right:'2px', width:'204px'}} className='signupButton HomePageGoToProjects'> Account not varified. Check email </div>

            // beta_message_element = <div className='beta_message'>{beta_message}</div>
        }

        link_buttons = [
            <div key='HomePageLogedInAs' className='HomePageLogedInAs'>{email}</div>,
            <div key='loginButton' className='loginButton HomePageLogOut'
                // onClick={props.sign_out_user.bind(this)}
                onClick={handleLogout}
            >
                Log out
            </div>,
            go_to_proj_button,
        ]
        if (is_beta_approved === true && user_is_varified === true){
            link_buttons.push(<UserItem key='userItem' onClick={handleUserClick} style={{height:'40px', width:'40px' }} element_id='UserSettingsIcon' icon="fas fa-vial"  name="bob gel" />)
        }
    }
    else{
        link_buttons = [
            <Link key='tt' className='loginButton'to='/login'>Log in</Link>,
            <Link key='yy' className='signupButton'to='/signup'>Sign up</Link>
        ]
    }
    
    return (
      <div className="HomeBaseDiv">
            <img key='aa' className="HomePageImg" src={bg_image} alt="logo" />

            <div  key='bbx'  className="HomePageScrollDiv" >
                {beta_message_element}
                <div className="HomePageBlurBG" >
                
                    <div className='homePageTitle'>StoryCraft <p className='homePageTitlebeta'>{'( alpha v' + String(Constants.APP_VERSION) + ' )'}</p></div>
                    <div className="homePageBlurb"> {blurb_string} </div> 
                    <div className="homePageBlurb_b"> {blurb_string_note} </div> 
                </div>
                
                <div className="HomePageBlock" >

                    <div className="HomePageBlurBG_left" >
                    
                        <div className='homePageHeading'>What makes a Story?</div>
                        <div className="homePageBlurb_b"> {blurb_string_b} </div> 
                    </div>
                    <img  key='xx' className="preview_image_right" src={flow_view_image} alt="logo" />

                </div>
                <div className="HomePageBlock" >

                    <div className="HomePageBlurBG_left" >
                    
                        <div className='homePageHeading'>Define your Characters</div>
                        <div className="homePageBlurb_b"> {blurb_string_c} </div> 
                    </div>
                    <img className="preview_image_right" src={char_view_image} alt="logo" />

                </div>
                <div className="HomePageBlock" >

                    <div className="HomePageBlurBG_left" >
                    
                        <div className='homePageHeading'>Create Locations</div>
                        <div className="homePageBlurb_b"> {blurb_string_d} </div> 
                    </div>
                    <img  key='xyx' className="preview_image_right" src={map_view_image} alt="logo" />

                </div>
                
                <div className="HomePageBlock" >
                    <div className="HomePageBlurBG_left" >
                    
                        <div className='homePageHeading'>Block out your story</div>
                        <div className="homePageBlurb_b"> {blurb_string_e} </div> 

                    </div>
                    <img  key='zzz' className="preview_image_right" src={timeline_map_view_image} alt="logo" />
                </div>
            </div>

            <div key='gfd' className="HomeTopDiv">
                {link_buttons}
            </div>

        <ModalWindowGenerator/>
        {foldoutPanelBuild()}

      </div>
    );
  }
  


