import * as misc_utils from './misc_utils'


export const get_thread_connected_cards = ( thread_data, thread_ids=null ) => {
    /*
        buld a dict of card connetions for use by other functons.
        if no thread_ids provided find for all
    */
    let card_connection_data = {};
    if (!thread_ids){
        thread_ids = Object.keys(thread_data);
        // i need to filter out - ideally i wouldnt store cur_dragging in this way. oh well. something for later
        thread_ids = thread_ids.filter(item => item !== 'cur_dragging')
    }


    for (let index = 0; index < thread_ids.length; index++) {

        let card_connection_data_per_thread = {};

        const thread_col_id = thread_ids[index];
        let connections = thread_data[thread_col_id]['connections']

        for (let i = 0; i < Object.keys(connections).length; i++) {
            let out_card = connections[Object.keys(connections)[i]][1][1]
            let in_card = connections[Object.keys(connections)[i]][0][1]
            

            // cards could ave multipe children or parents, they shoudl only total 2 though...
            if (in_card in card_connection_data_per_thread) {
                card_connection_data_per_thread[in_card].push(out_card)
            } else {
                card_connection_data_per_thread[in_card] = [out_card]
            }
    
            if (out_card in card_connection_data_per_thread) {
                card_connection_data_per_thread[out_card].push(in_card)
            } else {
                card_connection_data_per_thread[out_card] = [in_card]
            }
        }
        card_connection_data[thread_col_id] = card_connection_data_per_thread;
    }
    return card_connection_data
}



export const get_thread_connected_cards_b = ( thread_data, card_data, thread_ids=null ) => {
    /*
        buld a dict of card connetions for use by other functons.
        if no thread_ids provided find for all
    */
    let card_connection_data = {};
    if (!thread_ids){
        thread_ids = Object.keys(thread_data);
        // i need to filter out - ideally i wouldnt store cur_dragging in this way. oh well. something for later
        thread_ids = thread_ids.filter(item => item !== 'cur_dragging')
    }


    for (let index = 0; index < thread_ids.length; index++) {

        let card_connection_data_per_thread = {};

        const thread_col_id = thread_ids[index];
        let connections = thread_data[thread_col_id]['connections']

        for (let i = 0; i < Object.keys(connections).length; i++) {
            let connection_id = Object.keys(connections)[i]
            let out_card = connections[connection_id][1][1]
            let in_card = connections[connection_id][0][1]
            
            let out_card_data = get_simple_card_data( out_card, card_data);
            let in_card_data = get_simple_card_data( in_card, card_data);

            //add extra data to get_simple_card_data
            if (out_card_data){
                out_card_data['connection_id'] = connection_id;
            }
            if (in_card_data){
                in_card_data['connection_id'] = connection_id;
            }
            // cards could ave multipe children or parents, they shoudl only total 2 though...
            if (in_card in card_connection_data_per_thread) {
                card_connection_data_per_thread[in_card].push([out_card, out_card_data])
            } else {
                card_connection_data_per_thread[in_card] = [[out_card, out_card_data]]
            }
    
            if (out_card in card_connection_data_per_thread) {
                card_connection_data_per_thread[out_card].push([in_card, in_card_data])
            } else {
                card_connection_data_per_thread[out_card] = [[in_card, in_card_data]]
            }
        }
        card_connection_data[thread_col_id] = card_connection_data_per_thread;
    }
    return card_connection_data
}

export const get_card_thread_anchors = ( thread_data, card_data,  ) => {
    /*
        buld a dict of card connetions for use by other functons.
        if no thread_ids provided find for all
    */
        let card_anchor_data = {};
        let thread_ids = Object.keys(thread_data);
        thread_ids = thread_ids.filter(item => item !== 'cur_dragging')


        for (let index = 0; index < thread_ids.length; index++) {
     
            const thread_col_id = thread_ids[index];
            let connections = thread_data[thread_col_id]['connections']
    
            for (let i = 0; i < Object.keys(connections).length; i++) {
                let connection_id = Object.keys(connections)[i]
                let out_card = connections[connection_id][1][1]
                let in_card = connections[connection_id][0][1]

                // if card_anchor_data has my card, then check if it already has thread Id. If not add
                if (!card_anchor_data.hasOwnProperty(out_card)){
                    card_anchor_data[out_card] = [];
                }
                if(!card_anchor_data[out_card].includes(thread_col_id)){
                    card_anchor_data[out_card].push(thread_col_id)
                }
        
                
                if (!card_anchor_data.hasOwnProperty(in_card)){
                    card_anchor_data[in_card] = [];
                }
                if(!card_anchor_data[in_card].includes(thread_col_id)){
                    card_anchor_data[in_card].push(thread_col_id)
                }
            }
                
        }
        return card_anchor_data
}


const get_simple_card_data = ( card_id, card_data) => {
    let card = card_data[card_id];
    if (!card){
        // console.log('get_simple_card_data no card found', card_id);
        return null;
    }
    let card_centre = [
        card.pos[0] + (card.size[0] *.5) ,
        card.pos[1] + (card.size[1] *.5) ,
    ]
    return {
        item_parent:card.item_parent,
        pos:card.pos,
        size:card.size,
        title:card.title,
        centre:card_centre,
    } 
}





export const find_connections_to_break = ( card_ids, thread_data, thread_ids=null ) => {
    /*
        buld a arrays of connection ids which are connected to things outside of given card_ids array.
        This is useful for disconnecting when dragging and dropping cards to new parents.
        eg. disconnted any connecions to cards outside list but keep connections where possible

        if no thread_ids provided find for all
    */
    let list_of_connections_to_break = {};

    if (!thread_ids){
        thread_ids = Object.keys(thread_data);
        // i need to filter out - ideally i wouldnt store cur_dragging in this way. oh well. something for later
        thread_ids = thread_ids.filter(item => item !== 'cur_dragging')
    }

    for (let index = 0; index < thread_ids.length; index++) {

        let list_of_connections_to_break_per_thread = [];

        const thread_col_id = thread_ids[index];
        let connections = thread_data[thread_col_id]['connections']

        for (let i = 0; i < Object.keys(connections).length; i++) {
            let connection_id = Object.keys(connections)[i];
            let out_card = connections[connection_id][1][1]
            let in_card = connections[connection_id][0][1]
            
            // if outcard is in card ids but not in, then i should break this connection
            if (card_ids.includes(out_card) && !card_ids.includes(in_card)){
                if (!list_of_connections_to_break_per_thread.includes(connection_id)){
                    list_of_connections_to_break_per_thread.push(Object.keys(connections)[i])
                }
            }
            // and vice versa
            if (!card_ids.includes(out_card) && card_ids.includes(in_card)){
                if (!list_of_connections_to_break_per_thread.includes(connection_id)){
                    list_of_connections_to_break_per_thread.push(Object.keys(connections)[i])
                }
            }
        }
        list_of_connections_to_break[thread_col_id] = list_of_connections_to_break_per_thread;
    }
    return list_of_connections_to_break
}



export const test_thread_connection = (thread_id, dragging, hovering, clicked, thread_data, card_data, thread_connected_cards) => {
    /*
        things to test. If ok to connect return some data about what to connect
        if not ok, perhaps return something that will allow me to style in some way - eg, turn threads black

        -   if dragging thread, always allow to reconnect to where it came from
        -   if hovering id is the same as dragging then do not set
        -   if start or end con is mouse then dont set it dragging over self
        -   dont' allow setting thread target if it exactly matches an existing thread connection (eg double up)

        -   if card already has two connections, 
                and limit connectons is true for that thread id
                then dont set
                OR
                do set but return which thread will be deleted on mouse up
                    those threads can get styled black or with a cross (something like that)

        - test if dragging a new connection (shouldl be able to test by looking for 'mouse')
            if 2 connectons already exist (or if current connections are more than 2?) 
            then return the closest thread in extra data to turn it black.

        - test for circular connections
            make recursive function that returns list of all connected to a card (recursively)
            if source card is in that list then return false with no connection

    */
    // console.log('test_thread_connection', thread_id, dragging, hovering, clicked, thread_data, card_data, thread_connected_cards);
    // let do_set_thread_target = false;
    // let do_set_thread_target = true;

    let threads_to_disconnect = [];

    let thread_dragging = thread_data.cur_dragging
    let dragging_thread_id = thread_dragging[0];
    let dragging_con_id = thread_dragging[1];
    let dragging_start_or_end = thread_dragging[2];

    // reworked clicked data for thread to conform to
    let clicked_id = Object.keys(clicked)[0]
    let clicked_thread_id = clicked[clicked_id].custom_data.thread_id;
    let clicked_con_id = clicked[clicked_id].custom_data.con_id;
    let clicked_start_or_end = clicked[clicked_id].custom_data.start_or_end;
    let clicked_pre_drag_cons = clicked[clicked_id].custom_data.pre_drag_cons;

    let start_or_end_index = 0;
    if (clicked_start_or_end === 'end') start_or_end_index = 1;

    let clicked_con_card = null;
    if (clicked_pre_drag_cons){
        clicked_con_card = clicked_pre_drag_cons[start_or_end_index][1]
        // console.log('THIS IS SOURCE CARD', clicked_con_card);
    }

    // get connected cards to thread
    let cons = thread_data[dragging_thread_id].connections[dragging_con_id]
    let hovering_card_id = hovering[1];
    let dragging_card_id = dragging[1];

    // console.log(' DD hovering_card_id', hovering_card_id);
    // console.log('DD dragging_card_id', dragging_card_id);


    // Im thinking if i can rework this to deal with source and target cards it might be less confusing
    // source would be what's attached to the side of the thread which is not being moved
    // target would be the proposed card to connect (i guess what is being hovered over)
    let source_card_id = null;
    let target_card_id = null;
    if (thread_dragging){
        if (dragging_start_or_end === 'start'){
            source_card_id = cons[1][1];
        }
        if (dragging_start_or_end === 'end'){
            source_card_id = cons[0][1];
        }
    }
    if (hovering[0] === 'card'){
        target_card_id = hovering_card_id;
    }
    // console.log(' X -  source_card_id', source_card_id);
    // console.log('X -  target_card_id', target_card_id);



    // if dragging is not the right type then exit
    if (dragging[0] !== 'cardAnchor' && dragging[0] !== 'cardThread') {
        return [false, null];
    }

//    console.log('test_thread_connection - thread_connected_cards', thread_connected_cards);
    if (!thread_connected_cards){
        return [false, null];
    }

    // I dont know if i need this. might be bandaid for other problem
    if (!thread_connected_cards.hasOwnProperty(thread_id)){
        return [false, null];
    }

    // if dragging is not the right type then exit
    if (dragging[0] !== 'cardAnchor' && dragging[0] !== 'cardThread') {
        return [false, null];
    }

    //if not hovering over a card or card anchor then can return out straight away
    if (hovering[0] !== 'card' && hovering[0] !== 'cardAnchor') {
        return [false, null];
    }
    // build data table - ultimately it ,might be more efficient to store this data in redux or similar
    // let thread_connected_cards = get_thread_connected_cards( thread_data, [thread_id] )





    // If hovering over same card that was already connencted before dragging, then always allow to reconnect
    // Using return here because I don't need to run any more logic if this is true
    // This shoud only be relivant for dragging thread - dragging from anchor won't have clicked_pre_drag_cons
    if (clicked_pre_drag_cons){
        if (hovering_card_id === clicked_con_card){
            // do_set_thread_target=true;
            return [true, null];
        }
    }


    //  if hovering id is the same as dragging then do not set
        // This should stop snapping to own card
    // if (hovering_card_id !== dragging_card_id) {
    //     do_set_thread_target=true;
    // }

    if (source_card_id === target_card_id) {
        // do_set_thread_target=true;
        return [false, null];
    }


    // if start or end con is mouse then dont set if dragging over self
    // not returning false at this stage because I'm later allowing it to be connected back to the card it was 
    // disconnected to 
    // NOTE - moved re-connect logic higher, so can now return here and save extra logic below
    if (cons){
        if (cons[0][0] === 'mouse' && cons[1][1] === hovering[1]){
            // do_set_thread_target=false;
            return [false, null];
        }
        if (cons[1][0] === 'mouse' && cons[0][1] === hovering[1]){
            // do_set_thread_target=false;
            // return false;
            return [false, null];
        }
    }




    // find out what connections the card we're hovering over has. Then we can run logic for whether we can connect to it or not 

    let hovering_card_cons = [];
    if (thread_connected_cards[thread_id].hasOwnProperty(target_card_id)){
        hovering_card_cons = thread_connected_cards[thread_id][target_card_id];
    }

    // Don't allow connection to card which is already connected with this thread ids.
    // this is done by looking up into the hovering cards cons to see if source card is in there.
    for (let index = 0; index < hovering_card_cons.length; index++) {
        let connected = hovering_card_cons[index][0];
        if (connected){
            if (connected === source_card_id){
                return [false, null];
            }
        }
    }


    // The following is for logic for saying which conections shoudl be disconnected
    // this is to avoid the source or target cards ending up with more than 2 connections
    // if thread id has limited connections (eg a single storyline thread without branching)
    if (thread_data[thread_id]['limit_connections']){
        if (hovering_card_cons.length > 1){

            let source_card_centre = [
                card_data[source_card_id].pos[0] + (card_data[source_card_id].size[0] * .5),
                card_data[source_card_id].pos[1] + (card_data[source_card_id].size[1] * .5),
            ]

            // loop through all connected cards (atm this should always be 2), find which 
            let shortest_dist = 10000000000;
            let closest_card_id = null;
            let closest_card_connection_id = null;
            for (let index = 0; index < hovering_card_cons.length; index++) {
                let connected_id = hovering_card_cons[index][0];
                let connected_data = hovering_card_cons[index][1];

                let dist = misc_utils.get_distance(source_card_centre, connected_data.centre)
                if (dist < shortest_dist){
                    shortest_dist = dist;
                    closest_card_id = connected_id;
                    closest_card_connection_id = connected_data.connection_id;
                }
            }

            threads_to_disconnect.push(closest_card_connection_id)

        }




        // test for when dragging new thread 
        // test if source card already had 2 connections or more. if so find closest connected 
        /// and set extra data

        let source_card_cons = [];
        if (thread_connected_cards[thread_id].hasOwnProperty(source_card_id)){
            source_card_cons = thread_connected_cards[thread_id][source_card_id];
        }

            // if thread id has limited connections (eg a single storyline thread without branching)
        if (thread_data[thread_id]['limit_connections']){
            if (source_card_cons.length > 2){
                let target_card_centre = [
                    card_data[target_card_id].pos[0] + (card_data[target_card_id].size[0] * .5),
                    card_data[target_card_id].pos[1] + (card_data[target_card_id].size[1] * .5),
                ]
    
                // loop through all connected cards (atm this should always be 2), find which 
                let shortest_dist = 10000000000;
                let closest_card_id = null;
                let closest_card_connection_id = null;
                for (let index = 0; index < source_card_cons.length; index++) {
                    let connected_id = source_card_cons[index][0];
                    let connected_data = source_card_cons[index][1];

                    if (connected_data){
                        let dist = misc_utils.get_distance(target_card_centre, connected_data.centre)
                        if (dist < shortest_dist){
                            shortest_dist = dist;
                            closest_card_id = connected_id;
                            closest_card_connection_id = connected_data.connection_id;
                        }
                    }

                }
                threads_to_disconnect.push(closest_card_connection_id)
            }
        }
    }

    
    let test_thread_extra_data = {
        info:'Target card already has maximum number of connections.\nReplacing connection of card closest to source card',
        connection_ids:threads_to_disconnect,
        thread_id:thread_id,
        action:'disconnect_thread',
    }
    // console.log('0-test_thread_extra_data threads_to_disconnect', threads_to_disconnect);
    return [true, test_thread_extra_data];
    // return [true, null];

}

