
import * as card_utils from '../../utils/card_utils'
// import * as utils from '../../utils/misc_utils'

const reducer_cache = (state, newState, action) => {

    if(action.type === 'SET_CACHE_DATA'){
        //AAA console.log('RD SET_THREAD_CUR_DRAGGING', action)

        //todo this needs to be done in an immutable way. Not updating nicely with nested cache data
        newState.cache_data = {...state.cache_data};

        // if (newState.ui_data.cache_data.hasOwnProperty(action.key)){
        //     newState.ui_data.cache_data[action.key] = {...state.ui_data.cache_data.action.key};
        // }
        // newState.cache_data = utils.deepCopyFunction(newState.cache_data)
        newState.cache_data[action.key] = action.data;
    }


    if(action.type === 'CACHE_THREAD_CONNECTIONS'){
        // console.log('RD CACHE_THREAD_CONNECTIONS', action)

        newState.cache_data = {...state.cache_data};

        // if (newState.ui_data.cache_data.hasOwnProperty(action.key)){
        //     newState.ui_data.cache_data[action.key] = {...state.ui_data.cache_data.action.key};
        // }
        let thread_connection_data = card_utils.get_thread_connected_cards_b( state.thread_data, state.card_data, null )
        newState.cache_data['thread_connection_data'] = thread_connection_data;
    }

    if(action.type === 'CACHE_CARD_THREAD_ANCHORS'){
        // console.log('RD CACHE_CARD_THREAD_ANCHORS', action)

        newState.cache_data = {...state.cache_data};

        // if (newState.ui_data.cache_data.hasOwnProperty(action.key)){
        //     newState.ui_data.cache_data[action.key] = {...state.ui_data.cache_data.action.key};
        // }
        let card_thread_anchor_data = card_utils.get_card_thread_anchors( state.thread_data, state.card_data)
        newState.cache_data['card_thread_anchor_data'] = card_thread_anchor_data;
    }

    return newState;
};
export default reducer_cache;