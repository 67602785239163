// import React from 'react';
import React, { Component } from 'react';
import { Route, BrowserRouter as Router, Switch, Link, useHistory} from 'react-router-dom';

import { connect} from 'react-redux';

import './BannerPanel.css';
import UserItem from '../ui_elements/objects/user_item/UserItem';
import UserPanel from '../ui_elements/objects/user_item/UserPanel';
import InputField from '../ui_elements/inputs/InputField';


import * as actionCreator_ui from '../../store/actions/actions_ui';
import * as actionCreator_database from '../../store/actions/actions_database';
import * as actionCreator_storyItems from '../../store/actions/actions_story_items';
import * as actionCreator_projects from '../../store/actions/actions_projects';

import * as actionCreator_auth from '../../store/actions/actions_auth';
import * as actionCreator_undo from '../../store/actions/actions_undo';
import * as actionCreator_storage from '../../store/actions/actions_storage';

import * as Constants from '../../constants/constants';

import * as utils from '../../utils/misc_utils'
import * as maintenance_utils from '../../utils/maintenance_utils'

// import homer from '../images/homer_icon_512.jpg';

//then in the render function of Jsx insert the mainLogo variable

var IMAGES_PATH = '../../images/';
var HOMER_PATH = '../../images/homer_icon_512.jpg';


class BannerPanel extends Component {
    constructor(props) {
        super(props);
        this.handleFullScreenClick = this.handleFullScreenClick.bind(this);
        this.handleUserClick = this.handleUserClick.bind(this);
        this.handleTestClick_B = this.handleTestClick_B.bind(this);
        this.handleTestClick_C = this.handleTestClick_C.bind(this);
        this.handleUserIconClick = this.handleUserIconClick.bind(this);
        this.handleProjectsButton = this.handleProjectsButton.bind(this);
        this.handleTesting = this.handleTesting.bind(this);
        this.handleUndo = this.handleUndo.bind(this);
        this.handleRedo = this.handleRedo.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.handleHover = this.handleHover.bind(this);

        this.state = {
            hovering:null,
        }

    }

    handleHover(which) {
        // console.log('hovering over buttton', which);
        this.setState({hovering:which})
    }
    handleFullScreenClick() {
        this.props.onFullScreenToggle();
    }
    handleUserClick() {
        console.log('THIS IS A TEST BUTTON')

        // let bbox = utils.get_element_bbox('UserSettingsIcon')
        // console.log('   -   bbox', bbox)
        let panel_name = 'user_settings'
        let element_id = 'UserSettingsIcon'
        // let element_id = 'BannerLabel'
        this.props.open_foldout_panel(panel_name, element_id);

        // this.props.load_project_data_from_db(['newProject_03718501195347166'], 'test4/project_data')
        // this.props.crappy_test()

        // this.props.testButtonDB();
    }

    handleProjectsButton(event) {
        // this.props.testButtonDB();
        // this.props.push_data_to_db('story_app_data', this.props.full_state);
        this.props.pick_project_screen(event)
        this.setState({hovering:null})

    }
    handleTesting(event) {
        // this.props.testButtonDB();
        // this.props.push_data_to_db('story_app_data', this.props.full_state);
        // console.log('handleTesting')
        // alert('this is a test message')
        // alert('An email will be sent to ' + 'email' + '\nPlease click on the provided link to varify your account.')

        // this.props.show_testing_panel_toggle();
        let test_text = 'Thanks for signing up to StoryCraft!\nAn account varification email has been sent to: \n' + 'email' + '\n ----- \nPlease click on the provided link to varify,\nthen refresh this page to continue.'
        this.props.open_modal_window('message', {title:'Varify Email Account', className:'modalMessageBiggerText modalMessageSelectableText', cancellable:false, win_height:'450px',  win_width:'980px', message:test_text, okButtonLabel:'Got it!'});

        
    }
    handleTestClick_C() {
        // console.log('handleTestClick_C')

        ///story_app_root_3/users/210jl1vNZ2P3F1iqE26uKwMJxnn2/projects/exampleProject_210jl1vNZ2P3F1iqE26uKwMJxnn2/character_data
        let project_id = 'exampleProject_210jl1vNZ2P3F1iqE26uKwMJxnn2'
        let user_id = '210jl1vNZ2P3F1iqE26uKwMJxnn2'
        // let storage_path = 'character_data'
        let storage_path = ''
        this.props.test_get_user_storage_size(storage_path, user_id, project_id)



    }
    handleTestClick_B() {
        console.log('THIS IS A TEST BUTTON B',)

        //maintenance_utils.fix_all_projects_to_have_project_and_project_data();

        alert(
            '\n\nThis feature is not working yet.\nIn the future, it might bring up options for:\n\
            - share project\n\
            - archive project\n\
            - give feedback\n\
            - show tutorial hints' 
            
            
        )


        // this.props.testButtonDB();
        // this.props.push_data_to_db('story_app_data', this.props.full_state);
        // this.props.testButton_B('story_app_data', this.props.full_state, false, 0)
        // this.props.test_context()
        // let current_user_id = this.props.context_data.user;
        // console.log('THIS IS A TEST BUTTON B current_user_id', current_user_id)
        // console.log('THIS IS A TEST BUTTON B user_data', this.props.user_data)
        // this.props.create_example_project(current_user_id, this.props.user_data)

          // item_data, item_type, item_id, to_link_type, to_link_id
    }
    handleUserIconClick() {
        // this.props.testButtonDB();
        // this.props.load_root_data_from_db('story_app_data');

    }
    handleUndo() {
        console.log('CLIKCED THIS handleUndo', this.props.undo_data)
        this.props.do_undo_redo('undo', this.props.undo_data);

        // this.props.testButtonDB();
        // this.props.load_root_data_from_db('story_app_data');

    }
    handleRedo() {
        console.log('CLIKCED THIS handleRedo', this.props.undo_data)
        this.props.do_undo_redo('redo', this.props.undo_data);
        // this.props.testButtonDB();
        // this.props.load_root_data_from_db('story_app_data');

    }


    onKeyDown(event){
        let key_down = event.key;
        console.log('BAnner event', event)
        console.log('BAnner onKeyDown', key_down)

        if (key_down == "Control") { 
            console.log('BAnner ctl')
        }
        if (key_down == "Shift") { 
            console.log('BAnner Shift')
        }
        if (key_down == "Alt") { 
            console.log('BAnner Alt')
        }
        
    }
    


    render () {

        let bannerStyleA = {
            // height:this.props.banner_height+'px',
            height:this.props.ui_data.layout.banner_height+'px',
            // backgroundColor:'orange',
        };
        // let user_icon = <i id="UserIcon"  className="fas fa-vial"></i>;
        // let user_icon = <UserItem id='UserSettingsIcon' icon="fas fa-vial"  name="bob gel" />;

        // let user_panel = null;
        // // if (this.props.ui_data.modal_data.hasOwnProperty('user_panel')){
        // if (this.props.ui_data.open_user_panel){
        //     user_panel = <UserPanel/>;
            
        // }

        // add dissable pointer class based on dragging
        let disable_pointer_class = ''
        if (this.props.ui_data.control.begin_drag){
            disable_pointer_class = 'DisablPointer';
        }

        let undo_disable_style = {};
        if (this.props.undo_data.index === this.props.undo_data.history.length){
            undo_disable_style =  {opacity:'.2', pointerEvents:'none'};;
        }
        let redo_disable_style = {};
        if (this.props.undo_data.index === 0){
            redo_disable_style = {opacity:'.2', pointerEvents:'none'};
        }

        let experimental = this.props.ui_data.settings.experimental_mode;
        return (
            <div id="BannerPanel" style={bannerStyleA} className={disable_pointer_class}
                onKeyDown={this.onKeyDown}
            > 
                <i key='1' id="FullScreenIcon" onClick={this.handleFullScreenClick} className="fas fa-expand"
                    onMouseOver={() => this.handleHover('FullScreenIcon')} 
                    onMouseLeave={() => this.handleHover(null)} 
                />
                {experimental && <i key='2' id="projectSettingsIcon" onClick={this.handleTestClick_B}  className="fas fa-cog"></i>}
                {experimental &&
                    Constants.DEV_MODE && 
                        [
                            <i key='3t' id="TestingPanelButtonIcon" onClick={this.handleTesting}  style={{color:'red'}} className="fas fa-vial"></i>,
                            <i key='3ta' id="TestingButtonIcon" onClick={this.handleTestClick_C}  className="fas fa-vial"></i>,
                            <i key='3u' id="UndoButton" onClick={this.handleUndo}  className="fas fa-undo-alt" style={undo_disable_style}></i>,
                            <i key='3r' id="RedoButton" onClick={this.handleRedo}  className="fas fa-redo-alt" style={redo_disable_style}></i>,
                        ]
                    
                }
                <UserItem onClick={this.handleUserClick} element_id='UserSettingsIcon' icon="fas fa-vial" name="bob gel" />
                {this.props.ui_data.current.current_project !== null && [
                    <i key='3' id="HomeButton" className="fas fa-book"
                        onClick={this.handleProjectsButton} 
                        onMouseOver={() => this.handleHover('homeButton')} 
                        onMouseLeave={() => this.handleHover(null)} 
                    />,

                    <InputField 
                        className="ProjectField"
                        dataSource={"projects." + this.props.ui_data.current.current_project + ".name"}
                        dataTarget={"projects." + this.props.ui_data.current.current_project + ".name"}
                        inputType='text' 
                        key='ProjectField1'
                    />
                ]}
                {this.state.hovering === 'homeButton' && this.props.ui_data.current.current_project !== null &&
                    <p className='homeButtonHoverLabel'>Back to Projects</p>
                }
                {this.state.hovering === 'FullScreenIcon'  &&
                    <p className='fullScreenHoverLabel'>Full Screen</p>
                }

                <Link id='BannerLabel'to='/'>StoryCraft</Link>
                <div key='5' id="VersionLabel"> {'v' + String(Constants.APP_VERSION)} </div> 

            </div>
        )
    }
}

// const UserSettings = (props) => {
//     return (
//             <span id="UserSettings"> 
//             <UserItem name='homer' icon={"url(../../images/homer_icon_512.jpg)"} />
//             </span> 
//     )
// }


const mapDispatchToProps = (dispatch) => {
    return {
        // onFullScreenToggle: () => dispatch({type:'FULL_SCREEN_TOGGLE'}),
        onFullScreenToggle: () => dispatch(actionCreator_ui.full_screen_toggle()),

        open_foldout_panel: (panel_name, element_id) => dispatch(actionCreator_ui.open_foldout_panel(panel_name, element_id)),

        testButton: () => dispatch({type:'ON_MODAL_OPEN', modalName:'test'}),
        open_modal_window: (modal_name, input_data) => dispatch(actionCreator_ui.open_modal_window(modal_name, input_data)),

        // testButtonDB: () => dispatch({type:'TEST_DB'}),
        
        // push_data_to_db: (data) => dispatch({type:'PUSH_DATA_TO_DB', data:data}),
        
        onModeChange: (mode) => dispatch({type:'SET_MODE', value:mode}),
        
        // testButtonDB: (event,) => dispatch(actionCreator_database.test_action(event,)),
        
        // set_db_data: (db_path, db_data, skip, delay) => dispatch(actionCreator_database.set_db_data(db_path, db_data, skip, delay)),
        // load_root_data_from_db: (db_path) => dispatch(actionCreator_database.load_root_data_from_db(db_path)),
        
        pick_project_screen: () => dispatch(actionCreator_projects.set_current_project( null)),

        test_context: () => dispatch({type:'TEST_CONTEXT'}),

        test_add_user: (event) => dispatch(actionCreator_auth.test_add_user(event)),
        sign_in_user: (event) => dispatch(actionCreator_auth.sign_in_user(event)),
        sign_out_user: (event) => dispatch(actionCreator_auth.sign_out_user(event)),
        
        show_testing_panel_toggle: () => dispatch(actionCreator_ui.show_testing_panel_toggle()),
        do_undo_redo: (mode, undo_data) => dispatch(actionCreator_undo.do_undo_redo(mode, undo_data)),
        
        test_get_user_storage_size: (storage_path, user_id, project_id) => dispatch(actionCreator_storage.test_get_user_storage_size(storage_path,  user_id, project_id, null, null)),
        // copy_current_state_to_db: (data) => dispatch(actionCreator_database.copy_current_state_to_db(data)),

        // crappy_test: (event) => dispatch(actionCreator_database.crappy_proj_thread_data_test(event)),
        // load_project_data_from_db: (project_ids, db_path) => dispatch(actionCreator_database.load_project_data_from_db(project_ids, db_path)),

        // create_example_project: (user_id, user_data) => dispatch(actionCreator_projects.create_example_project(user_id, user_data)),

    }
};
const mapStateToProps = (state) => {
  return {
    full_state:state,
    ui_data:state.ui_data,
    project_data:state.project_data,
    projects:state.projects,
    undo_data:state.undo_data,
    // user_data:state.user_data,
    // context_data:state.context_data,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BannerPanel);

