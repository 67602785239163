import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import ModalWindow from './ModalWindow';


export default function ModalWindowGenerator() {

    let openModalWindow_data = useSelector( state => state.ui_data.openModalWindow)
    // let modal_data = useSelector( state => state.ui_data.modal_data)

    let new_modal_window = null;
    if (openModalWindow_data){
        // loop thorugh all keys (probably will be only 1, and find which modal window keys are there - then test the val, if any are not null the open)

        let modal_keys = Object.keys(openModalWindow_data)
        for (let i = 0; i < modal_keys.length; i++){
            if  (openModalWindow_data[modal_keys[i]]){
            // console.log('====>>>>> GOT MODAL HERE   ', modal_keys[i], openModalWindow_data[modal_keys[i]])
    
            switch (modal_keys[i]) {
                case 'create_character':
                    new_modal_window=<ModalWindow 
                        windowTitle='Create New Character'
                        modalName='create_character'
                        //childUI ={<CreateNewCharacter item_type='character'/>}
                    />;
                    break;
                
    
                case 'create_prop':
                    new_modal_window=<ModalWindow 
                        windowTitle='Create New Prop'
                        modalName='create_prop'
                        //childUI ={<CreateNewCharacter item_type='prop'/>}
                    />
                    break;
    
                case 'create_location':
                    new_modal_window=<ModalWindow 
                        windowTitle='Create New Location'
                        modalName='create_location'
                        //childUI ={<CreateNewCharacter item_type='location'/>}
                    />
                    break;
            
                case 'create_event':
                    new_modal_window=<ModalWindow 
                        windowTitle='Create New Event'
                        modalName='create_event'
                        //childUI ={<CreateNewCharacter item_type='location'/>}
                    />
                break;
            
                case 'link_items':
                    new_modal_window=<ModalWindow 
                        windowTitle='Link Items'
                        modalName='link_items'
                        okButtonLabel='Link Items'
                        //childUI ={<LinkItemsWindow item_type='card' item_id='uid1'/>}
                        //okButtonLabel='Link Item(s)'
                    />
                    break;
    
                case 'new_project':
                    new_modal_window=<ModalWindow 
                        windowTitle='create new project'
                        modalName='new_project'
                        okButtonLabel='Create New Project'
                    />
                break;
    
                case 'pick_image':
                    new_modal_window=<ModalWindow 
                        windowTitle='pick image'
                        modalName='pick_image'
                        okButtonLabel='OK'
                    />
                    break;
    
                case 'add_attrs':
                    new_modal_window=<ModalWindow 
                        windowTitle='Add Attributes'
                        modalName='add_attrs'
                        okButtonLabel='OK'
                    />
                    break;

                case 'user_settings':
                    new_modal_window=<ModalWindow 
                        windowTitle='User Settings'
                        modalName='user_settings'
                        okButtonLabel='OK'
                    />
                    break;
                
                case 'message':
                    let title = ''
                    if (openModalWindow_data['message'].hasOwnProperty('title')){
                        title = openModalWindow_data['message']['title']
                    }
                    let cancellable = true
                    if (openModalWindow_data['message'].hasOwnProperty('cancellable')){
                        cancellable = openModalWindow_data['message']['cancellable']
                    }
                    let okButtonLabel = 'OK'
                    if (openModalWindow_data['message'].hasOwnProperty('okButtonLabel')){
                        okButtonLabel = openModalWindow_data['message']['okButtonLabel']
                    }
                    // let cancellable = openModalWindow_data['message']['cancellable'] || true;
                    // console.log('MODAL GEN cancellable', cancellable)
                    new_modal_window=<ModalWindow 
                        windowTitle={title}
                        modalName='message'
                        okButtonLabel={okButtonLabel}
                        cancellable={cancellable}
                    />
                    break;
                }
            }
        }
    }
    return new_modal_window;
}



