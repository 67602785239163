import React, { Component } from 'react';
import './IndexCard.css';
// import jQuery;
import { connect} from 'react-redux';
import InputField from '../../inputs/InputField';

import * as actionCreator_mouse from '../../../../store/actions/actions_mouse';
import * as actionCreator_viewports from '../../../../store/actions/actions_viewports';
import * as actionCreator_timeline from '../../../../store/actions/actions_timelines';
import * as actionCreator_viewport_xforms from '../../../../store/actions/actions_viewport_xforms';
import * as actionCreator_cards from '../../../../store/actions/actions_cards';
import * as Constants from '../../../../constants/constants';

import * as actionCreator_documents from '../../../../store/actions/actions_documents';
import * as actionCreator_ui from '../../../../store/actions/actions_ui';
import * as actionCreator_cache from '../../../../store/actions/actions_cache';

import * as card_utils from '../../../../utils/card_utils'


class IndexCard extends Component {

    constructor(props) {
        super(props);
        this.handleCardMouseDown = this.handleCardMouseDown.bind(this);
        this.handleCardMouseDown_scale = this.handleCardMouseDown_scale.bind(this);
        this.handleAnchorMouseDown = this.handleAnchorMouseDown.bind(this);
        this.nestedMouseDown = this.nestedMouseDown.bind(this);
        this.handleOpenDoc = this.handleOpenDoc.bind(this);
        this.handleGoToTimelime = this.handleGoToTimelime.bind(this);
        this.handleOverAnchor = this.handleOverAnchor.bind(this);
        this.handleLeaveAnchor = this.handleLeaveAnchor.bind(this);
        // this.handleOnFous = this.handleOnFocus.bind(this);
        // this.handleOnBlurc = this.handleOnBlur.bind(this);
        this.state = {
            anchor_hover:false,
        }
    }
 
    handleCardMouseDown(event) {

        event.stopPropagation();

        // the order of these is important!
        this.props.onMouseDown(event, this.props.item_type, this.props.item_id,);
        this.props.onMouseDown_VP(event, this.props.ui_data, this.props.viewport_data);
        this.props.mouse_down_vp_xform(event, this.props.item_type, this.props.item_id,  this.props.item_type_data[this.props.item_id], this.props.ui_data);
        this.props.update_flow_viewport_cache();

    }

    handleCardMouseDown_scale(event) {

        event.stopPropagation();

        // the order of these is important!
        this.props.onMouseDown(event, this.props.item_type+'_scale', this.props.item_id,);
        this.props.onMouseDown_VP(event, this.props.ui_data, this.props.viewport_data);
        this.props.mouse_down_vp_xform(event, this.props.item_type+'_scale', this.props.item_id,  this.props.item_type_data[this.props.item_id], this.props.ui_data);
        this.props.update_flow_viewport_cache();

    }



    handleAnchorMouseDown(event) {
        event.stopPropagation();

        this.props.onMouseDown(event, 'cardAnchor', this.props.item_id,);
        this.props.onMouseDown_VP(event, this.props.ui_data, this.props.viewport_data);
        this.props.onMouseDown_anchor(event, this.props.item_id, this.props.current_thread_colour);
        // this.props.onAnchorMouseDown(event, 'cardAnchor', this.props.item_id, );
        this.props.update_flow_viewport_cache();

    
    };
    nestedMouseDown(event) {
        event.stopPropagation();
        this.props.onNestedMouseDown( 'flow_viewport', 'card_' + this.props.item_id,);
    
    };

    handleOpenDoc(event) {
        event.stopPropagation();
        let item_data =  this.props.item_type_data[this.props.item_id];
        // this.props.link_items(this.props.item_data, this.props.item_type, this.props.item_id, 'character', ['uid2', 'uid1']);
        if (item_data.links.hasOwnProperty('document'))
        {
            if (item_data.links.document){
                let linked_doc =item_data.links.document[0];
                this.props.open_linked_document(linked_doc);
            }
        }
    }
    handleGoToTimelime(event) {
        event.stopPropagation();
        console.log('DO handleGoToTimelime')
        this.props.onModeChange('timeline', this.props.ui_data)
        this.props.fit_timeline(this.props.ui_data, this.props.card_data);

    }


    handleOverAnchor(){
        this.setState({anchor_hover:true})
    }
    handleLeaveAnchor(){
        this.setState({anchor_hover:false})
    }

    // handleOnFocus(event) {
    //     console.log('HAS FOCUS!', event);
    // }
    // handleOnBlur(event) {
    //     console.log('HAS BLUR!', event);
    // }

    // TO DO - generate anchors here and add them later to card
    populate_card_anchors(extra_anchor_style, extra_anchor_click_style){
        let anchors_to_add=[]
        let anchor_count =1;
        let anchor_click_width = 60;   
        let anchor_width = 40;        
        let anchor_colour = this.props.thread_data[this.props.current_thread_colour]['color'];
        let hover_off_style = {};
        if (this.props.begin_drag){
            hover_off_style['pointerEvents'] = 'none';
        }

        if (this.props.item_id in this.props.card_thread_anchor_data){
            anchor_count = this.props.card_thread_anchor_data[this.props.item_id].length
        }

        for (let index = 0; index < anchor_count; index++) {
            let anchor_offset = ((anchor_count-1) * anchor_width *-.5) + (index * anchor_width) + (anchor_width*.5)
            let anchor_click_offset = ((anchor_count-1) * anchor_click_width *-.5) + (index * anchor_click_width)+ (anchor_click_width*.5)
        
            let anchor_colour = this.props.thread_data[this.props.card_thread_anchor_data[this.props.item_id][index]]['color'];


            // anchor_pos = Math.round((0.5 * this.props.size[0])-((anchor_count-1) * anchor_width * -.5)+(index*anchor_width))
            // anchor_click_pos = Math.round((0.5 * this.props.size[0])-((anchor_count-1) * anchor_click_width * -.5)+(index*anchor_click_width))
    

            let anchor_style = {
                left:Math.round((0.5 * this.props.size[0])-anchor_width + anchor_offset), 
                'borderColor': anchor_colour,
            };
            let anchor_click_style = {
                left:Math.round((0.5 * this.props.size[0])-anchor_click_width + anchor_click_offset), 
                'borderColor': anchor_colour,
            };


            anchors_to_add.push(
                <div 
                className='CardAnchor' 
                item_id={this.props.item_id}
                item_type='cardAnchor'
                style={Object.assign({}, anchor_click_style, hover_off_style, extra_anchor_click_style)}
                //onMouseDown={this.props.cardAnchor.bind(this, this )}
                onMouseDown={this.handleAnchorMouseDown}
                onTouchStart={this.handleAnchorMouseDown}
                onMouseOver={this.handleOverAnchor}
                onMouseLeave={this.handleLeaveAnchor}

                />,
                <div 
                    className='CardAnchorX' style={Object.assign({}, anchor_style, hover_off_style, extra_anchor_style)}
                />
            )
        }

        // inOffset = ((inPinCount-1) * pinWidth *-.5) + (inPinIndex * pinWidth)
        // outOffset = ((outPinCount-1) * pinWidth *-.5) + (outPinIndex * pinWidth)
        // // console.log('XXX inOffset ', inOffset, inCardId)

        // anchor_pos = Math.round((0.5 * this.props.size[0])-((anchor_count-1) * anchor_width * -.5)+(index*anchor_width))
        // anchor_click_pos = Math.round((0.5 * this.props.size[0])-((anchor_count-1) * anchor_click_width * -.5)+(index*anchor_click_width))


        // if (inCardId in this.props.card_thread_anchor_data){
        //     for (let index = 0; index < this.props.card_thread_anchor_data[inCardId].length; index++) {
        //         anchors_to_add.push(
        //             <div 
        //             className='CardAnchor' 
        //             item_id={this.props.item_id}
        //             item_type='cardAnchor'
        //             style={Object.assign({}, anchorStyle, hover_off_style)}
        //             //onMouseDown={this.props.cardAnchor.bind(this, this )}
        //             onMouseDown={this.handleAnchorMouseDown}
        //             onTouchStart={this.handleAnchorMouseDown}
        //             onMouseOver={this.handleOverAnchor}
        //             onMouseLeave={this.handleLeaveAnchor}

        //             />,
        //             <div 
        //                 className='CardAnchorX' style={Object.assign({}, anchorStyle_b, hover_off_style)}
        //             />
        //         )
        //     }
        // }
        return anchors_to_add;
    }


    render() {
        let item_id= this.props.item_id;
        let cardDraggingStyle={};
        let cardStyle = {};
        let apply_hover_class = '';
        let hover_off_style = {}
        let cardSelectedStyle = {};
        let spellcheck='false';
        let titleClassToUse = 'CardTitle';
        let textClassToUse = 'cardText';
        let focusClass = '';

        let anchorStyle={};
        let anchorStyle_b={};

        // I want to only stle focus if control.focus is set - which i'm doing on mouse up. This is to avoid focus flashng when dragging
        if (this.props.ui_data.control.focus){
            focusClass = 'CardInputFocus';
        }

        

        // let style = {
        //     left:this.props.item_type_data[this.props.item_id]['pos'][0] + '.px',
        //     top:this.props.item_type_data[this.props.item_id]['pos'][1] + '.px',
        //     width:this.props.item_type_data[this.props.item_id]['size'][0] + '.px',
        //     height:this.props.item_type_data[this.props.item_id]['size'][1] + '.px',
        // }
        // console.log('AXXX - this.props.pos', this.props.pos)
        // console.log('XXX - this.props.size', this.props.size)
        // console.log('XXX - this.props.item_id', this.props.item_id)
        // let style = {
        //     left:this.props.pos[0] + '.px',
        //     top:this.props.pos[1] + '.px',
        //     width:this.props.size[0] + '.px',
        //     height:this.props.size[1] + '.px',
        // }
        let style = {
            left:this.props.pos[0],
            top:this.props.pos[1],
            width:this.props.size[0],
            height:this.props.size[1],
        }

        // if selected, change style
        if (this.props.ui_data.control.selected){
            if (this.props.item_id in this.props.ui_data.control.selected){
                style['boxShadow'] = '0px 0px 0px 4px rgba(255, 255, 255, 1)';
                apply_hover_class = 'ApplyHover';

                cardSelectedStyle = {
                    boxShadow: '0px 0px 0px 4px rgba(255, 255, 255, 1)',
                    // borderColor: 'rgba(255, 255, 255, 1)',
                }
                // cardSelectedStyle['transform'] = 'rotate(0deg)  scale(1.02, 1.02)';
                

                titleClassToUse = 'CardTitleSel';
                textClassToUse = 'cardTextSel';
                spellcheck='true';
            }
        }

          // if dragging, change style
        //   if (this.props.ui_data.control.dragging_b){
        //     if (this.props.item_id in this.props.ui_data.control.dragging_b){
        //         style['pointerEvents'] = 'none';
        //     }
        // }      

        // test if in selection bbox
        let sel_bbox = this.props.ui_data.control.select_bbox;
        if (sel_bbox){
            let item_bbox = [
                this.props.pos[0],
                this.props.pos[1],
                this.props.pos[0] + this.props.size[0],
                this.props.pos[1] + this.props.size[1],
            ]
            if (
                sel_bbox[0] <= item_bbox[2] &&
                sel_bbox[1] <= item_bbox[3] &&
                sel_bbox[2] >= item_bbox[0] &&
                sel_bbox[3] >= item_bbox[1]
                ){
                    style['boxShadow'] = '0px 0px 0px 4px rgba(255, 255, 255, .5)';
            }
        }




  

        if (this.props.begin_drag){
            // if (this.props.dragging[0] === 'card'){
            //     if (this.props.dragging[1] === this.props.item_id){
            if (this.props.ui_data.control.dragging_b){

                if (item_id in this.props.ui_data.control.dragging_b){
                    if (this.props.ui_data.control.dragging_b[item_id].item_type === 'card'){

                        cardDraggingStyle = {
                            cursor: 'grabbing',
                            transform: 'translate(0px, 10px)',
                            transform: 'rotate(-2deg)  scale(1.06, 1.06)',
                            backgroundColor: 'rgb(245, 235, 190)',
                            boxShadow: '-8px 25px 60px 3px rgba(0, 0, 0, .12)',
                            pointerEvents: 'none',
                            zIndex: 1000,

                            
                        }
                        document.activeElement.blur();
                        titleClassToUse = 'CardTitle';
                        textClassToUse = 'cardText';
                
                    }
                }
            }
        }

        let documents = null;
        if (this.props.card_data[item_id].hasOwnProperty('links')) {
            if (this.props.card_data[item_id]['links'].hasOwnProperty('document')) {
                documents = this.props.card_data[item_id].links.document;
            }
        }
        let doc_style = {
            display:'none',
        } 
        if (documents){
            if (documents.length) {
                doc_style = {
                    display:'block',
                } 
            }
        }

        let time_style = {
            display:'none',
        } 
        if (this.props.card_data[item_id].hasOwnProperty('time')) {
            time_style = {
                display:'block',
            } 
        }


        


        if (this.props.dragging[0] === 'cardAnchor' || this.props.dragging[0] === 'cardThread'){
            
            // let do_set_thread_target = true;
            titleClassToUse = 'CardTitle';
            textClassToUse = 'cardText';
            let thread_dragging = this.props.thread_data.cur_dragging;
            let cons = this.props.thread_data[thread_dragging[0]].connections[this.props.dragging[1]];
            let thread_id = thread_dragging[0];
            let thread_connection_data = {};
            if (this.props.cache_data.hasOwnProperty('thread_connection_data')){
                    thread_connection_data = this.props.cache_data['thread_connection_data']
            }

            let test_thread_connection = card_utils.test_thread_connection(thread_id,  this.props.dragging, this.props.hovering, this.props.clicked, this.props.thread_data, this.props.card_data, thread_connection_data);
            let do_set_thread_target = test_thread_connection[0];
            let thread_test_extra_data = test_thread_connection[1];



            if (do_set_thread_target){
                // if (this.props.hovering[0] === 'card' && this.props.hovering[1] === item_id && this.props.dragging[1] !== item_id){
                if (this.props.hovering[0] === 'card' && this.props.hovering[1] === item_id){
                    if (this.props.dragging[1] === item_id){
                        style['pointerEvents'] = 'none';

                        // TODO deal with this
                        anchorStyle['pointerEvents'] = 'none';
                    }
                    else{
                        style['borderStyle'] = 'dashed';
                        style['borderColor'] = this.props.thread_data[this.props.thread_data.cur_dragging[0]]['color'];

                    }
                   
                }
            }


        }
            
            
        // TODO: Need to make this work with populate anchors
        // This is to hide anchor highlighting once in dragging mode for connection (this needs to be done after the one above)
        if (this.props.dragging[0] === 'cardAnchor' || this.props.dragging[0] === 'cardThread' && this.props.dragging[1] === item_id){
                anchorStyle['opacity'] = '0';
        }

        if (this.state.anchor_hover){
            if (!this.props.ui_data.control.dragging[0]){
                anchorStyle_b['opacity'] = .6;
            }
        }

        // do anchor styling here for offsetting for multiple pins
        // this.props.ui_data.current.current_thread_colour
        let anchor_colour = this.props.thread_data[this.props.current_thread_colour]['color'];
        anchorStyle_b['borderColor'] = anchor_colour;

        let card_anchors=[];
        let anchor_count =0;

        if (this.props.card_thread_anchor_data){
            if (this.props.item_id in this.props.card_thread_anchor_data){
                card_anchors = this.props.card_thread_anchor_data[this.props.item_id]
                anchor_count = card_anchors.length;
            }
        }
        let anchor_offset=-1;
        let anchor_width = 40;        
        let anchor_click_extra_width = 50;


        // this sets the visible element, ensuring it is offset the right amount
        for (let i = 0; i < Object.keys(this.props.thread_data).length; i++) {
            if (card_anchors.includes(Object.keys(this.props.thread_data)[i])){
                anchor_offset++;
                if (Object.keys(this.props.thread_data)[i] === this.props.current_thread_colour){
                    break;
                }
            }
            else{
                if (Object.keys(this.props.thread_data)[i] === this.props.current_thread_colour){
                    anchor_offset++;
                    anchor_count++;
                    break;
                }
            }
        }
        anchorStyle_b['left'] = Math.round((0.5 * this.props.size[0])-(anchor_count * anchor_width * .5) + (anchor_width * anchor_offset));

        // this sets the click area
        anchorStyle['left'] = Math.round((0.5 * this.props.size[0])-((anchor_count * anchor_width + anchor_click_extra_width) * .5));
        anchorStyle['width'] = Math.round(anchor_count * anchor_width + anchor_click_extra_width);


        let cardOverCardStyle = {}
        // this is to change style when hovering over another card for drag and drop behaviour
        if (this.props.dragging[0] === 'card'){

            if (this.props.hovering[0] === 'card'){

                if (this.props.dragging[1] === item_id && this.props.hovering[1] !== item_id){
                    cardOverCardStyle['transform'] = 'rotate(-6deg)  scale(.8, .8)';
                    cardOverCardStyle['zIndex'] = 1000;
                    
                }
                if (this.props.hovering[1] === item_id && this.props.dragging[1] !== item_id){
                    cardOverCardStyle = {
                        transform:'rotate(0deg)  scale(1.1, 1.1)',
                        borderStyle:'dashed',
                        zIndex:1,
                        backgroundColor:'rgb(230, 216, 155)',
                        boxShadow: "inset 1px 18px 20px 1px rgba(0, 0, 0, .1)",

                    }
                    // cardSelectedStyle={};
                }
            }
            if (this.props.hovering[0] === 'breadcrumb'){
                if (this.props.dragging[1] === item_id){
                    cardOverCardStyle['transform'] = 'rotate(-6deg)  scale(.5, .5)';
                    cardOverCardStyle['zIndex'] = 1000;
                    // cardSelectedStyle={};
                }
                // if (this.props.hovering[1] === item_id){
                //     cardOverCardStyle = {
                //         transform:'rotate(0deg)  scale(1.1, 1.1)',
                //         borderStyle:'dashed',
                //         zIndex:-1,
                //         backgroundColor:'rgb(230, 216, 155)',
                //         boxShadow: "inset 1px 18px 20px 1px rgba(0, 0, 0, .1)",

                //     }
                //     cardSelectedStyle={};
                // }
            }
        }

        let counter_style = {}
        if (this.props.child_count === 0){
            counter_style['display'] = 'none';
        }

        // This disables pointerevents so i dont trigger hover when dragging anything
        // if (this.props.dragging[0] !== null ) {
        if (this.props.begin_drag){
            hover_off_style['pointerEvents'] = 'none';
        }
        else{
            apply_hover_class = 'HoverIndexCard';
        }


        return (
            <div className={"indexCard " + apply_hover_class}
                id={this.props.item_id}
                item_id={this.props.item_id}
                item_type={this.props.item_type}
                style={Object.assign({}, style,   cardDraggingStyle, cardOverCardStyle, cardSelectedStyle)}

                onMouseDown={this.handleCardMouseDown}
                onTouchStart={this.handleCardMouseDown}
            >
                {Constants.DEV_MODE && 
                    <div className='card_uid_display_div'>
                        {this.props.item_id}
                    </div>
                }

                <InputField 
                        className={titleClassToUse + ' ' + focusClass}
                        inputPlaceholder='Title...'
                        dataSource={"card_data." + item_id + ".title"}
                        dataTarget={"card_data." + item_id + ".title"}
                        inputType='textFieldCard' 
                        //style={{position:"relative", userSelect:"none"}} 
                        id={'card_title_field'+item_id} 
                        label='' 
                        //spellCheck={spellcheck}
                        style={hover_off_style}
                        focusOnEnter={'card_synopsis_field'+item_id}

                />
                <div className='CardSeparator' />

                <InputField 
                    className={textClassToUse + ' ' + focusClass}
                    inputPlaceholder='Synopsis...'

                    dataSource={"card_data." + item_id + ".synopsis"}
                    dataTarget={"card_data." + item_id + ".synopsis"}

                    inputType='textAreaCard' 
                    //style={{position:"relative", userSelect:"none"}} 
                    id={'card_synopsis_field'+item_id} 

                    label='' 
                    //spellCheck={spellcheck}
                    style={hover_off_style}
                    // onFocus={this.props.handleOnFocus}
                    // onBlur={this.props.handleOnBlur}
                />
 

                <div 
                    className='CardAnchor' 
                    item_id={this.props.item_id}
                    item_type='cardAnchor'
                    style={Object.assign({}, anchorStyle, hover_off_style)}
                    //onMouseDown={this.props.cardAnchor.bind(this, this )}
                    onMouseDown={this.handleAnchorMouseDown}
                    onTouchStart={this.handleAnchorMouseDown}
                    onMouseOver={this.handleOverAnchor}
                    onMouseLeave={this.handleLeaveAnchor}

                />
                <div 
                    className='CardAnchorX' style={Object.assign({}, anchorStyle_b, hover_off_style)}
                /> 
                {/* {this.populate_card_anchors(anchorStyle_b, anchorStyle)} */}
                
                <i style={Object.assign({}, doc_style, hover_off_style)} id='DocumentIconShad' className="fas fa-file-alt"/>
                <i style={Object.assign({}, doc_style, hover_off_style)} id='DocumentIcon' className="fas fa-file-alt" 
                    onDoubleClick={this.handleOpenDoc}
                />

                <i style={Object.assign({}, time_style, hover_off_style)} id='TimeIconShad' className="fas fa-clock"></i>
                <i style={Object.assign({}, time_style, hover_off_style)} id='TimeIcon' className="fas fa-clock"
                    onDoubleClick={this.handleGoToTimelime}
                />

                <div className='NestedCardCounter'
                    style = {Object.assign({}, counter_style, hover_off_style)}
                    onMouseDown={this.nestedMouseDown}
                >
                    {this.props.child_count}
                </div>
                

                <div item_type='region_scale' onMouseDown={this.handleCardMouseDown_scale} className='RegionScaleHandle' />

            </div>
        );
    };
}

const mapStateToProps = (state, props) => {
    let current_vp = state.ui_data.current.current_viewport;
    let item_type = props.item_type;
    return {

        item_type_data:state[item_type+'_data'],
        viewport_data:state[current_vp[0]+'_data'][current_vp[1]],
        ui_data:state.ui_data,
        cache_data:state.cache_data,
        dragging:state.ui_data.control.dragging,
        hovering:state.ui_data.control.hovering,
        begin_drag:state.ui_data.control.begin_drag,
        clicked:state.ui_data.control.clicked,
        card_data:state.card_data,
        thread_data:state.thread_data,
        viewport_data:state[current_vp[0]+'_data'][current_vp[1]],
        // ui_data:state.ui_data,
        current_vp:state.ui_data.current.current_viewport,
        current_thread_colour:state.ui_data.current.current_thread_colour,
        card_thread_anchor_data:state.cache_data.card_thread_anchor_data,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onMouseDown: (event, item_type, item_id) => dispatch(actionCreator_mouse.mouse_down(event, item_type, item_id)),
        onMouseDown_VP: (event, ui_data, viewport_data) => dispatch(actionCreator_viewports.mouse_down_viewport(event, ui_data, viewport_data)),
        mouse_down_vp_xform: (event, item_type, item_id, item, ui_data) => dispatch(actionCreator_viewport_xforms.mouse_down_vp_xform(event, item_type, item_id, item, ui_data)),
        onMouseDown_anchor: (event, item_id, col_id) => dispatch(actionCreator_cards.mouse_down_anchor(event, item_id, col_id)),
        onNestedMouseDown: (vp_type, vp_id) => dispatch(actionCreator_viewports.set_current_viewport(vp_type, vp_id)),
        open_linked_document: (document_id) => dispatch(actionCreator_documents.open_linked_document(document_id)),
        onModeChange: (mode, ui_data) => dispatch(actionCreator_ui.mode_panel_set_mode(mode, ui_data)),
        update_flow_viewport_cache: () => dispatch(actionCreator_cache.update_flow_viewport_cache()),
        fit_timeline: (ui_data, card_data) => dispatch(actionCreator_timeline.fit_timeline (ui_data, card_data)),


    }
};
export default connect(mapStateToProps, mapDispatchToProps )(IndexCard);

